<template>
    <li ref="check" class="check">
        <button
            class="check-button"
            :aria-current="check.bboxIndex === activeBboxIndex"
            @click="setBbox"
        >
            <div class="circle" />
            <div class="title">{{ index + 1 }} van {{ total }}</div>
            <div class="page">Pag. {{ check.page }}</div>
        </button>
    </li>
</template>

<script>
export default {
    name: 'Check',
    props: {
        check: Object,
        index: Number,
        total: Number,
        activeBboxIndex: Number,
    },
    methods: {
        setBbox() {
            const { page, bboxIndex } = this.check
            this.$emit('setBbox', { page, bboxIndex })
        },
    },
    watch: {
        activeBboxIndex: {
            handler: function(newVal) {
                if (this.check.bboxIndex === newVal) {
                    this.$refs.check.scrollIntoView({ block: 'nearest' })
                }
            },
        },
    },
    mounted: function() {
        if (this.check.bboxIndex === this.activeBboxIndex) {
            this.$refs.check.scrollIntoView({ block: 'nearest' })
        }
    },
}
</script>

<style scoped>
.check:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-20);
}

.check-button {
    display: flex;
    width: 100%;
    user-select: none;
}

.check-button[aria-current] {
    background: var(--color-secondary-hover);
    pointer-events: none;
    -webkit-box-shadow: inset 0px 0px 0px 3px var(--color-primary-active);
    -moz-box-shadow: inset 0px 0px 0px 3px var(--color-primary-active);
    box-shadow: inset 0px 0px 0px 3px var(--color-primary-active);
}

.check-button[aria-current] .circle:before {
    display: none;
}
.check-button[aria-current] .circle:after {
    border-color: var(--color-primary);
}

.check-button[aria-current] .title,
.check-button[aria-current] .page {
    color: #076de9;
}

.check-button:not([aria-current]):hover .circle:before {
    background: var(--color-hover);
}
.check-button:not([aria-current]):active .circle:before {
    background: var(--color-active);
}
.check-button:not([aria-current]):hover .circle:after,
.check-button:not([aria-current]):active .circle:after {
    border-color: black;
}

.circle {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    height: 32px;
}

.check-button .circle:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.check-button .circle:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid var(--color-grey-50);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    box-sizing: border-box;
}

.title {
    padding: 5px 0 6px;
    font-size: var(--font-size-small);
}

.page {
    margin-left: auto;
    padding: 5px 12px 6px;
    font-size: var(--font-size-small);
    color: var(--color-text-grey);
}
</style>
