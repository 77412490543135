<template>
    <li class="rule">
        <div class="summary">
            <svg
                aria-hidden="true"
                width="22px"
                height="19px"
                viewBox="0 0 22 19"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <title>Kritieke fout:</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="warning_amber_black_24dp" fill="currentColor" fill-rule="nonzero">
                        <path
                            d="M11,3.99 L18.53,17 L3.47,17 L11,3.99 M11,0 L0,19 L22,19 L11,0 Z M12,14 L10,14 L10,16 L12,16 L12,14 Z M12,8 L10,8 L10,12 L12,12 L12,8 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </svg>
            {{ rule.summary }}
        </div>
        <FontList v-if="rule.fonts.length > 0" :fonts="rule.fonts" />
        <div class="description">
            {{ rule.description }}
        </div>
    </li>
</template>

<script>
import FontList from './FontList.vue'

export default {
    name: 'CriticalRule',
    props: {
        rule: Object,
    },
    components: {
        FontList,
    },
}
</script>

<style scoped>
.rule {
    margin: 32px 0;
}

.summary {
    display: flex;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    color: var(--color-alert);
    margin-bottom: 4px;
}

.summary svg {
    flex-shrink: 0;
    margin: 0 6px 2px 0;
    color: var(--color-alert);
}

.description {
    font-size: var(--font-size-small);
    color: var(--color-text-grey);
}
</style>
