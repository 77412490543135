<template>
    <div class="font-list">
        <ul class="font-list__list">
            <li
                class="font-list__list-item"
                v-for="(font, index) in fonts.slice(0, 5)"
                :key="index"
            >
                {{ font }}
            </li>
            <li
                class="font-list__list-item"
                v-if="showAllFonts"
                v-for="(font, index) in fonts.slice(5)"
                :key="index"
            >
                {{ font }}
            </li>
        </ul>
        <button
            class="font-list__toggle"
            @click="showAllFonts = !showAllFonts"
            v-if="fonts.length > 5"
        >
            {{ showAllFonts ? 'Verberg lettertypen' : 'Alle lettertypen zien?' }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'FontList',
    props: {
        fonts: Array,
    },
    data() {
        return {
            showAllFonts: false,
        }
    },
}
</script>

<style>
.font-list {
    margin-bottom: 16px;
}
.font-list__list {
    list-style-type: disc;
    margin-left: 32px;
}
.font-list__list-item {
    list-style-position: inside;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    color: var(--color-alert);
}
.font-list__toggle {
    text-decoration: underline;
    margin-left: 32px;
    margin-top: 8px;
    font-size: var(--font-size-normal);
}
</style>
