<template>
    <li class="category" :class="{ isExpanded }">
        <h2
            @click="toggleExpanded"
            :aria-expanded="isExpanded.toString()"
            :aria-pressable="false"
            tabindex="-1"
        >
            <button class="toggle">
                <div class="name-and-number-wrapper">
                    <span class="name">{{ name }}</span>
                    <span class="number">
                        ({{ rules.length }}<span class="hide-visually">punten</span>)
                    </span>
                </div>
                <div class="chevron">
                    <svg
                        aria-hidden="true"
                        width="10px"
                        height="6px"
                        viewBox="0 0 10 6"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g
                                id="icon/collapsed"
                                transform="translate(-15.000000, -17.000000)"
                                fill="currentColor"
                                fill-rule="nonzero"
                            >
                                <polygon
                                    id="Path"
                                    points="16.1417004 17 19.8582996 20.708502 23.5748988 17 24.7165992 18.1417004 19.8582996 23 15 18.1417004"
                                ></polygon>
                            </g>
                        </g>
                    </svg>
                </div>
            </button>
        </h2>
        <ul class="rules" v-if="isExpanded">
            <Rule
                v-for="(rule, index) in sortedRules"
                v-bind:key="rule.id"
                :guid="`${guid}-${index}`"
                :rule="rule"
                :activeBboxIndex="activeBboxIndex"
                @setBbox="setBbox"
            />
        </ul>
    </li>
</template>

<script>
import Rule from '@/components/Rule.vue'

export default {
    name: 'Category',
    components: {
        Rule,
    },
    props: {
        guid: String,
        name: String,
        rules: Array,
        activeBboxIndex: Number,
    },
    data() {
        return {
            isExpanded: false,
            sortedRules: this.rules,
        }
    },
    watch: {
        activeBboxIndex: {
            handler: function(newVal) {
                if (!this.isExpanded) {
                    let includesBbox = false
                    this.rules.forEach(rule => {
                        if (rule.checks.find(check => check.bboxIndex === newVal)) {
                            includesBbox = true
                        }
                    })
                    if (includesBbox) {
                        this.isExpanded = true
                    }
                }
            },
        },
    },
    created: function() {
        // Show errors above warnings
        this.sortedRules.sort(this.sortByProperty('status'))
    },
    methods: {
        sortByProperty(property) {
            let sortOrder = 1
            if (property[0] === '-') {
                sortOrder = -1
                property = property.substr(1)
            }
            return function(a, b) {
                const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
                return result * sortOrder
            }
        },
        toggleExpanded() {
            this.isExpanded = !this.isExpanded
        },
        setBbox(page) {
            this.$emit('setBbox', page)
        },
    },
}
</script>

<style scoped>
.category {
    margin-bottom: 24px;
    border: 2px solid var(--color-grey-40);
    border-radius: var(--radius-normal);
}

.toggle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.toggle:hover {
    background: var(--color-hover);
}

.toggle:active {
    background: var(--color-active);
}

.name-and-number-wrapper {
    margin-left: 16px;
    line-height: 1;
}

.name {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
}

.number {
    margin: 0 0 3px 4px;
    font-size: var(--font-size-small);
    font-weight: normal;
    color: var(--color-text-grey);
}

.chevron {
    margin-left: auto;
    flex-shrink: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-icon-grey);
}

.category.isExpanded .chevron {
    transform: scaleY(-1);
    color: black;
}

.rules {
    border-top: 2px solid var(--color-grey-40);
}
</style>
