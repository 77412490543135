import { render, staticRenderFns } from "./CriticalRule.vue?vue&type=template&id=65647290&scoped=true&"
import script from "./CriticalRule.vue?vue&type=script&lang=js&"
export * from "./CriticalRule.vue?vue&type=script&lang=js&"
import style0 from "./CriticalRule.vue?vue&type=style&index=0&id=65647290&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65647290",
  null
  
)

export default component.exports