<template>
    <li class="rule" :class="{ isExpanded }">
        <button
            class="toggle"
            @click="toggleExpanded"
            :aria-expanded="isExpanded.toString()"
            :aria-describedby="guid"
        >
            <div class="type">
                <ErrorIcon v-if="rule.status === 'error' || rule.status === 'serious'" />
                <WarningIcon v-if="rule.status === 'warning'" />
            </div>
            <div class="title">{{ createRuleSummary() }}</div>
            <div class="chevron">
                <svg
                    aria-hidden="true"
                    width="10px"
                    height="6px"
                    viewBox="0 0 10 6"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g
                            id="icon/collapsed"
                            transform="translate(-15.000000, -17.000000)"
                            fill="currentColor"
                            fill-rule="nonzero"
                        >
                            <polygon
                                id="Path"
                                points="16.1417004 17 19.8582996 20.708502 23.5748988 17 24.7165992 18.1417004 19.8582996 23 15 18.1417004"
                            ></polygon>
                        </g>
                    </g>
                </svg>
            </div>
        </button>
        <div v-if="isExpanded">
            <div class="description" tabindex="0">
                {{ rule.description }}
            </div>
            <div
                v-if="(rule.technicalSummary || rule.technicalDescription) && isExpandedTechnical"
                class="technical-description"
            >
                <div v-if="rule.technicalSummary" class="technical-description__summary">
                    {{ rule.technicalSummary }}
                </div>
                <div v-if="rule.technicalDescription" class="technical-description__description">
                    {{ rule.technicalDescription }}
                </div>
            </div>
            <button
                v-if="rule.technicalSummary || rule.technicalDescription"
                class="technical-button"
                @click="toggleTechnicalExpanded"
            >
                {{
                    isExpandedTechnical
                        ? $t('results.hideTechnicalDescription')
                        : $t('results.showTechnicalDescription')
                }}
            </button>

            <ul class="checks">
                <Check
                    v-for="(check, index) in rule.checks"
                    v-bind:key="index"
                    :check="check"
                    :index="index"
                    :total="rule.checks.length"
                    :activeBboxIndex="activeBboxIndex"
                    @setBbox="setBbox"
                    @expand="expand"
                />
            </ul>
        </div>
    </li>
</template>

<script>
import Check from '@/components/Check.vue'
import ErrorIcon from '@/components/ErrorIcon.vue'
import WarningIcon from '@/components/WarningIcon.vue'

export default {
    name: 'Rule',
    components: {
        Check,
        ErrorIcon,
        WarningIcon,
    },
    props: {
        guid: String,
        rule: Object,
        activeBboxIndex: Number,
    },
    data() {
        return {
            isExpanded: false,
            isExpandedTechnical: false,
            RuleSummary: '',
        }
    },
    watch: {
        activeBboxIndex: {
            handler: function(newVal) {
                if (
                    !this.isExpanded &&
                    !!this.rule.checks.find(check => check.bboxIndex === newVal)
                ) {
                    this.isExpanded = true
                }
            },
        },
    },
    created: function() {
        if (
            !this.isExpanded &&
            !!this.rule.checks.find(check => check.bboxIndex === this.activeBboxIndex)
        ) {
            this.isExpanded = true
        }
    },
    methods: {
        toggleExpanded() {
            this.isExpanded = !this.isExpanded
        },
        toggleTechnicalExpanded() {
            this.isExpandedTechnical = !this.isExpandedTechnical
        },
        expand() {
            this.isExpanded = true
        },
        setBbox(page) {
            this.$emit('setBbox', page)
        },
        createRuleSummary() {
            if (this.rule.fonts.length > 0) {
                return `${this.rule.summary} ${this.rule.fonts.reduce(
                    (text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' en ') + value
                )}.`
            } else return this.rule.summary
        },
    },
}
</script>

<style scoped>
.rule:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-30);
}

.toggle {
    display: flex;
    width: 100%;
}

.toggle:hover {
    background: var(--color-hover);
}

.toggle:active {
    background: var(--color-active);
}

.type,
.chevron {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chevron {
    margin-left: auto;
    color: var(--color-icon-grey);
}

.rule.isExpanded .chevron {
    transform: scaleY(-1);
    color: black;
}

.title {
    padding: 8px 0;
    font-size: var(--font-size-normal);
}

.description {
    font-size: var(--font-size-small);
    color: var(--color-text-grey);
    padding: 0 16px;
    margin-bottom: 12px;
}

@media (min-width: 768px) {
    .description {
        padding: 0 40px;
    }
}

.checks {
    margin-bottom: 12px;
}

.fixed-all {
    margin: 0 auto 12px;
}

.technical-button {
    padding: 0 40px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-grey);
    margin-bottom: 8px;
}

.technical-description {
    border: 1px solid var(--color-grey-30);
    margin: 0 40px;
    padding: 4px 8px;
    margin-bottom: 12px;
    color: var(--color-text-grey);
    font-size: var(--font-size-small);
}

.technical-description__summary {
    font-weight: var(--font-weight-bold);
}
</style>
