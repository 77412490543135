<template>
    <div>
        <p>
            Pagina niet gevonden.
        </p>
        <div class="back-button">
            <router-link to="/" class="button large tertiary">
                <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g
                            id="arrow_back_black_24dp"
                            transform="translate(-4.000000, -4.000000)"
                            fill="currentColor"
                            fill-rule="nonzero"
                        >
                            <polygon
                                id="Path"
                                points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13"
                            ></polygon>
                        </g>
                    </g>
                </svg>
                Terug naar begin</router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style scoped>
.back-button {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}
</style>
